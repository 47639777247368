import React from 'react';
import ReactDom from 'react-dom';

import HeaderNav from './_components/HeaderNav';
import SideMenu from './_components/SideMenu';

const { path } = document.getElementById('dummyBody').dataset;

ReactDom.render(<HeaderNav path={path} />, document.getElementById('headerNav'));
ReactDom.render(<SideMenu path={path} />, document.getElementById('sideMenu'));



