import React from 'react';
import { SlideDown } from 'react-slidedown';

import type { VFC, ReactNode } from 'react';

type Props = {
  children: ReactNode,
  open: boolean,
  duration?: string,
};

const Down: VFC<Props> = ({ children, open, duration = '0.25s' }) => {

  return (
    <SlideDown
      style={
        {
          transitionDuration: duration,
        }
      }
      transitionOnAppear={false}
    >
      {open && (
        <>{children}</>
      )}
    </SlideDown>
  );
};

export default Down;