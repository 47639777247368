import React from 'react';
import clsx from 'clsx';

import type { VFC } from 'react';

import styles from './_styles/HamburgerBtn.module.scss';

type Props = {
  onClick: (e: any) => void,
  active: boolean,
};

const HamburgerBtn: VFC<Props> = ({ onClick, active }) => {
  return (
    <button className={clsx(styles.btn, active && styles.btnActive)} onClick={onClick}>
      <span className={clsx(styles.bar, active && styles.barActive)} />
      <span className={clsx(styles.bar, active && styles.barActive)} />
      <span className={clsx(styles.bar, active && styles.barActive)} />
    </button>
  )
};

export default HamburgerBtn;