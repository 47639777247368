import React, { useState } from 'react';

import type { VFC } from 'react';

import SlideDown from './SlideDown';

import 'react-slidedown/lib/slidedown.css';
import styles from './_styles/SideMenu.module.scss';
import { listenerCount } from 'node:process';

type Props = {
  path: string,
};

const data = JSON.parse(document.getElementById('sideMenuJson').textContent);

const SideMenu: VFC<Props> = ({ path }) => {
  const [open, setOpen] = useState(0);

  const handleOpen = (e) => {
    const value = Number(e.currentTarget.value);
    // console.log(value)
    if (value === open) {
      setOpen(0);
    } else {
      setOpen(value);
    }
  };

  return (
    <>
      {
        data.map(({ title, list, value }) => {
          if (!list.length) return null;
          return (
            <div className={styles.menuWrap} key={value}>
              <button className={styles.btn} value={value} onClick={handleOpen}>{title}</button>
              <SlideDown open={value === open}>
                <ul className={styles.menu}>
                  {
                    list.map((item, index) => {
                      return (
                        <li className={styles.item} key={index}>
                          <a className={styles.link} href={`${path}landing/?landing=${item.landing}`}>{item.title}</a>
                        </li>
                      );
                    })
                  }
                </ul>
              </SlideDown>
            </div>
          )
        })
      }

    </>
  )
};

export default SideMenu;