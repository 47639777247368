import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import type { VFC } from 'react';

import HamburgerBtn from './HamburgerBtn';
import SlideDown from './SlideDown';

import 'react-slidedown/lib/slidedown.css';
import styles from './_styles/HeaderNav.module.scss';

type Props = {
  path: string,
};

const HeaderNav: VFC<Props> = ({ path }) => {
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState('0.25s');

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      setOpen(true);
      setDuration('0s');
    }
  }, []);

  return (
    <>
      <div className={styles.humburgerWrap}>
        <HamburgerBtn active={open} onClick={handleOpen} />
      </div>
      <div className={styles.navWrap}>
        <SlideDown open={open} duration={duration}>
          <nav className={styles.nav}>
            <ul className={styles.menu}>
              <li className={styles.item}>
                <a className={styles.link} href={`${path}./.`}>HOME</a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={`${path}reason.html`}>選ばれる理由</a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={`${path}service/`}>技術サービス</a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={`${path}product/`}>製品事例</a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={`${path}suggest/`}>技術提案集</a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={`${path}column/`}>技術コラム</a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={`${path}faq/`}>よくある質問</a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={`https://www.shinei-ksf.co.jp/`} target={"_blank"}>運営会社</a>
              </li>
              <li className={clsx(styles.item, styles.itemSp)}>
                <a className={styles.link} href={`${path}reason.html`}>選ばれる理由</a>
              </li>
              <li className={clsx(styles.item, styles.itemSp)}>
                <a className={styles.link} href={`${path}flow.html`}>お問合せからの流れ</a>
              </li>
              <li className={styles.item}>
                <a className={styles.link} href={`${path}contact.html`}>お問い合わせ</a>
              </li>
              <li className={clsx(styles.item, styles.itemSp)}>
                <a className={styles.link} href={`${path}contact.html`}>技術資料ダウンロード</a>
              </li>
              <li className={clsx(styles.item, styles.itemSp)}>
                <a className={styles.link} href={`${path}#`}>プライバシーポリシー</a>
              </li>
            </ul>
          </nav>
        </SlideDown>
      </div>
    </>

  )
};

export default HeaderNav;